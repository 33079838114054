import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Image from "next/image";

import { Device } from "@/utils/device";
import { H5, CS } from "@/components/Typography";
import RecommendationBadge from "../RecommendationCards/RecommendationBadge";
import RecommendationDivider from "../RecommendationCards/RecommendationDivider";
import { colours } from "@/utils";

type StyleProps = {
  background?: string;
};

interface WooboxMembershipCardProps {
  type: "accelerate" | "board" | "elevate" | "townhouse";
}

const WooboxMembershipCard: FunctionComponent<WooboxMembershipCardProps> = (
  props
) => {
  const type = props.type;
  const cards = {
    accelerate: {
      background: `linear-gradient(45deg, ${colours.accelerateOrange}, ${colours.accelerateLightOrange})`,
      copy: "For managers, early-stage founders or mid-level professionals, ready to transform and accelerate their career journey.",
      dividerColour: colours.shell,
      image: {
        src: "/images/accelerate/accelerate-woman-square.png",
        alt: "a portrait of a woman with multi-coloured sparks on an orange background",
      },
      subtitle: "Immersive, inspiring, eye-opening",
      textColour: colours.shell,
      title: "AllBright Accelerate",
    },
    board: {
      background: `linear-gradient(45deg, ${colours.marketingGreen}, ${colours.corporateGreen})`,
      copy: "For high performing individuals to get exclusive 1:1 training to become Board members in top firms.",
      dividerColour: colours.shell,
      image: {
        src: "/images/elevate/board-woman-square.png",
        alt: "a portrait of a woman with multi-coloured sparks on an orange background",
      },
      subtitle: "Add-on course for Board candidates",
      textColour: colours.shell,
      title: "Elevate to the Board",
    },
    elevate: {
      background: `linear-gradient(45deg, ${colours.clubBlue}, ${colours.marketingBlue})`,
      copy: "For senior leaders, established founders or C-suite executives, ready to elevate their professional journey to the very top.",
      dividerColour: colours.shell,
      image: {
        src: "/images/elevate/elevate-woman-square.png",
        alt: "a portrait of a woman with multi-coloured sparks on a blue background",
      },
      subtitle: "Intensive, hands-on, invaluable",
      textColour: colours.shell,
      title: "AllBright Elevate",
    },
    townhouse: {
      background: `linear-gradient(45deg, ${colours.marketingYellow}, #FFD869)`,
      copy: "For managers, early-stage founders or mid-level professionals, ready to transform and accelerate their career journey.",
      dividerColour: colours.teal,
      image: {
        src: "/images/townhouse/townhouse-woman-square.png",
        alt: "a portrait of a woman with multi-coloured sparks on an orange background",
      },
      subtitle: "Safe, connected, in the loop",
      textColour: colours.charcoal,
      title: "AllBright Townhouse",
    },
  };

  if (!props.type) return null;

  return (
    <S.Container
      background={cards[type].background}
      data-cy={`${type}-woobox-card`}
    >
      <S.ImageContainer>
        <Image
          src={cards[type].image.src}
          alt={cards[type].image.alt}
          layout="fill"
          objectFit="contain"
        />
      </S.ImageContainer>
      <RecommendationBadge
        background={colours.teal}
        category="Programme"
        colour={colours.shell}
      />
      <div>
        <H5 colour={cards[type].textColour}>{cards[type].title}</H5>
        <CS colour={cards[type].textColour}>{cards[type].subtitle}</CS>
        <S.DividerContainer>
          <RecommendationDivider colour={cards[type].dividerColour} />
        </S.DividerContainer>
      </div>
      <S.Description colour={cards[type].textColour}>
        {cards[type].copy}
      </S.Description>
    </S.Container>
  );
};

const S = () => {};

S.Container = styled.article<StyleProps>`
  position: relative;
  padding: 5%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: start;
  border-radius: 5px;
  background: ${(p) => p.background};
`;

S.ImageContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;

  @supports not (aspect-ratio: 1) {
    &::before {
      float: left;
      padding-top: 100%;
      content: "";
    }
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
`;

S.DividerContainer = styled.div`
  display: none;

  @media (min-width: ${Device.mobile}) {
    display: block;
  }
`;

S.Description = styled(CS)`
  display: none;

  @media (min-width: ${Device.mobile}) {
    display: block;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
`;

export default WooboxMembershipCard;
