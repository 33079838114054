import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Device } from "../../utils/devices";
import colours from "../../utils/colours";
import MarketingLink from "./MarketingLink";

type StyleProps = {
  backgroundColour?: string;
  backgroundPattern?: string;
  textColour?: string;
};

type CtaStripProps = {
  copy: string;
  linkText: string;
  linkUrl: string;
  backgroundColour?: string;
  backgroundPattern?: string;
  textColour?: string;
  target?: "_blank" | "self";
};

const CtaStrip: FunctionComponent<CtaStripProps> = (props) => {
  return (
    <S.Section {...props} data-cy="cta-strip">
      <S.Title {...props}>{props.copy}</S.Title>
      <MarketingLink
        href={props.linkUrl}
        colour={props.textColour || colours.charcoal}
        textColour={props.textColour}
        animationColour={colours.marketingBeige}
        linkText={props.linkText}
        target={props.target}
      />
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section<StyleProps>`
  width: 100%;
  padding: 10% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: ${(p) => p.backgroundColour || colours.marketingYellow};

  ${(p) =>
    p.backgroundPattern === "orange" &&
    `
    background-image: url('/images/marketing/marketing-vector-orange.svg');
    background-color: #F5AC97;;
  `}

  ${(p) =>
    p.backgroundPattern === "yellow" &&
    `
    background-image: url('/images/marketing/marketing-vector-yellow.svg');
    background-color: #FDE191;
  `}

  ${(p) =>
    p.backgroundPattern === "navy" &&
    `
    background-image: url('/images/marketing/marketing-vector-navy.svg');
    background-color: #222B59;
  `}

  ${(p) =>
    p.backgroundPattern === "green" &&
    `
    background-image: url('/images/marketing/marketing-vector-green.svg');
    background-color: #004C44;
  `}

  @media(min-width: ${Device.mobile}) {
    gap: 20px;
  }

  @media (min-width: ${Device.tablet}) {
    padding: 5% 10%;
    gap: 30px;
    align-items: start;
  }

  @media (min-width: ${Device.desktop}) {
    gap: 40px;
  }
`;

S.Title = styled.h4<StyleProps>`
  font-family:
    Roxborough CF,
    sans-serif;
  font-weight: 100;
  font-size: 3rem;
  text-align: center;
  line-height: 3.2rem;
  color: ${(p) => p.textColour || colours.charcoal};

  @media (min-width: ${Device.mobile}) {
    font-size: 4rem;
    line-height: 4rem;
  }

  @media (min-width: ${Device.tablet}) {
    text-align: start;
    font-size: 5rem;
    line-height: 5rem;
  }

  @media (min-width: ${Device.desktop}) {
    font-size: 6rem;
    line-height: 6rem;
  }
`;

export default CtaStrip;
